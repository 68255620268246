import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Features = () => {
  
  return (
    <Container>
      <Row>
        

        <Col md={4} sm={6}>
          <div className="feature-thumb">
            <span>01</span>
            <h3>Job Placement</h3>
            <p>We provide full Placement assistance to choose your dream career.</p>
          </div>
        </Col>

        <Col md={4} sm={4}>
          <div className="feature-thumb">
            <span>02</span>
            <h3>Certified Experience</h3>
            <p>We prove experience certificate for projects you have done.</p>
          </div>
        </Col>

        <Col md={4} sm={6}>
          <div className="feature-thumb">
            <span>03</span>
            <h3>Trending Courses</h3>
            <p>We offer you the latest technology courses to stay updated
             in this competitive world. 
             </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Features;
