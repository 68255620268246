import React from 'react'

import android from "../images/ic kang/ic_android.png";
import kotlin from "../images/ic kang/ic_kotlin.png";
import java from "../images/ic kang/ic_java.jpg";
import js from "../images/ic kang/ic_js.png";
import html from "../images/ic kang/ic_html_css.png";
import python from "../images/ic kang/ic_py.png";
import { Row, Container, Col, Card, Button } from 'react-bootstrap';

function FullStack() {

    return (
        <section id='follstack'>
        <Container>
          <Row>
            <Col md={12} sm={12}>
              <div className="section-title">
                <h1>Full Stack Web Development
                  <small>With Industrial Training</small>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={4} md={4}>
                <Card className="courses-thumb">
                  <div className="courses-top">
                    <div className="courses-image">
                      <Card.Img src={js} alt="" />
                    </div>
                    <div className="courses-date">
                      <span>
                        <i className="fa fa-clock-o"></i> 5-6 Months
                      </span>
                    </div>
                  </div>
  
                  <Card.Body className="courses-detail">
                    <h3>
                      MERN Stack
                    </h3>
                    <p>
                      <ul>
                        <li>MongoDB Database</li>
                        <li>ExpressJS back-end</li>
                        <li>ReactJS front-end</li>
                        <li>NodeJS</li>
                      </ul>
                    </p>
                  </Card.Body>
                  <Card.Footer className="courses-price">
                  {/* <a href="#contact">
                    <Button>Enroll Now</Button>
                  </a> */}
                  </Card.Footer>
                  <div className="courses-info"></div>
                </Card>
            </Col>

            <Col lg={4} sm={4} md={4}>
                <Card className="courses-thumb">
                  <div className="courses-top">
                    <div className="courses-image">
                      <Card.Img src={java} alt="" />
                    </div>
                    <div className="courses-date">
                      <span>
                        <i className="fa fa-clock-o"></i> 5-6 Months
                      </span>
                    </div>
                  </div>
  
                  <Card.Body className="courses-detail">
                    <h3>
                      Java Full Stack
                    </h3>
                    <p>
                    <ul>
                        <li>MySQL Database</li>
                        <li>Spring Boot back-end</li>
                        <li>ReactJS front-end</li>
                        <li>Hibernate ORM JPA</li>
                      </ul>
                    </p>
                  </Card.Body>
                  <Card.Footer className="courses-price">
                  {/* <a href="#contact">
                    <Button>Enroll Now</Button>
                  </a> */}
                  </Card.Footer>
                  <div className="courses-info"></div>
                </Card>
            </Col>

            <Col lg={4} sm={4} md={4}>
                <Card className="courses-thumb">
                  <div className="courses-top">
                    <div className="courses-image">
                      <Card.Img src={python} alt="" />
                    </div>
                    <div className="courses-date">
                      <span>
                        <i className="fa fa-clock-o"></i> 5-6 Months
                      </span>
                    </div>
                  </div>
  
                  <Card.Body className="courses-detail">
                    <h3>
                      Python Full Stack
                    </h3>
                    <p>
                    <ul>
                        <li>MySQL Database</li>
                        <li>Django/Flask back-end</li>
                        <li>ReactJS front-end</li>
                        <li>SQL Alchemy ORM</li>
                      </ul>
                    </p>
                  </Card.Body>
                  <Card.Footer className="courses-price">
                  {/* <a href="#contact">
                  <Button>Enroll Now</Button>
                  </a> */}
                  </Card.Footer>
                  <div className="courses-info"></div>
                </Card>
            </Col>
          </Row>
        </Container>
      </section>
  )
}

export default FullStack