import React from "react";
import { Container, Row,Col } from "react-bootstrap";

const About = () => {
  return (
    <section id="about">
      <Container>
        <Row>
          <Col md={6} sm={6}>
            <Col md={12} sm={12}>
              <div className="about-section-title">
                <h2>About Us</h2>
              </div>
            </Col>

            <Row>
              <p>
                Every single day we thought that tomorrow we will start working
                on our dream, We also know that tomorrow never occurred.
                "technoweit" exactly known this student problem, we not just
                solve it but also show student a way to achieve there dream job
                in a friendly atmosphere. "technoweit" working in training field
                since last year and guide hundreds of students who rarely get
                any guidance.
              </p>
              <p>
                "technoweit" is professional mobile app and website development
                training provider, and having a goal to train students,
                currently working person for their education and economical
                growth. Join "technoweit" now and get one near to your  dream.
              </p>
            </Row>
          </Col>

          <Col md={6} sm={6}>
            <Col md={12} sm={12}>
              <div className="about-section-title">
                <h2>Our Mission</h2>
              </div>
            </Col>

            <Row>
              <p>
                "technoweit" have vision to empower students to
                become super specialist in the field of moden day technologies.
                "technoweit" help the for there all over development. Our
                organization not only guide you to become employee but also help to
                start your career as per your choice. We just have one vision that provide
                quality education to students at affordable price.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
