import "./App.css";
import NavBar from "./Componants/NavBar";
import Home from "./Componants/Home";
import Features from "./Componants/Features";
import About from "./Componants/About";

import "bootstrap/dist/css/bootstrap.min.css"

import "react-owl-carousel2/lib/styles.css"

import "font-awesome/css/font-awesome.min.css"
import Courses from "./Componants/Courses";
import Testimonials from "./Componants/Testimonials";
import ContactUs from "./Componants/ContactUs";
import Footer from "./Componants/Footer";
import FullStack from "./Componants/FullStack";

function App() {
  return (
    <>
      <NavBar />
      <Home />
      <Features />
      <About />
      <FullStack/>
      <Courses/>
      <Testimonials/>
      <ContactUs/>
      <Footer/>
    </>
  );
}

export default App;
